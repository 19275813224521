import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/Image/Logo.png"
import "../../Styles/Header.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
function Header() {
    const navigate = useNavigate();
    const [state,setState] = useState(false)
    const [dropDown,setDropDown] = useState(false)
    return (
        <div className="container" style={{background:'#333F44',}}>
            <input  type='checkbox' className="input-check" id="menu-bar"/>
            <label for="menu-bar" className="header-label">Menu</label>
           

            <nav>
                
                <div className="wrapper">
                    <div className="logo">
                        <NavLink to="/"><img  src={Logo} alt="" className="logo-img"/></NavLink>
                    </div>
                    <ul className="nav-links">
                        
                        <li><NavLink to="/">Home</NavLink></li>
                       
                        <li>
                            <NavLink to="#" className="desktop-item">Services</NavLink>
                            <input type="checkbox" id="showDrop" />
                            <label htmlFor="showDrop" className="mobile-item">Services</label>

                            <ul className="drop-menu set-mobile">
                                <li><NavLink to="/services/utility">Utility Monitoring</NavLink></li>
                                <li><NavLink to="/services/energy">Energy Monitoring</NavLink></li>
                                <li><NavLink to="/services/Predictive&Preventive-maintenance">Predictive Analysis & Preventive Maintenance</NavLink></li>
                                <li><NavLink to="/services/Production-monitoring">Production Monitoring</NavLink></li>
                            </ul>
                        </li>
                        <li><NavLink to="/about">About</NavLink></li>
                        <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                        <li><button to="#" className="nav-button" onClick={() => window.open('https://demo-apps.sitesenz.com/web-app/#/user/login', '_blank')}>Sign in </button></li>
                        <li><button to="#" className="nav-button" onClick={()=>navigate('/contact-us')}>Free Trail </button></li>
                    </ul>
                    <label htmlFor="menu-btn" className="btn menu-btn"><i className="fas fa-bars"></i></label>         
                    <div className="menu-btn"  onClick={()=>setState(true)}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                
            </nav>
           <div className={state?'responsive block':'responsive none'}>
           <ul className="nav-links">
                        <label htmlFor="close-btn" id='close-btn' className={state?"":"close-btn"} onClick={()=>{setState(false) ;setDropDown(false)}}> X </label>
                        <li><NavLink to="/">Home</NavLink></li>         
                        <li>
                            <NavLink to="#" className="desktop-item">Services</NavLink>
                            <label htmlFor="showDrop" className="mobile-item" onClick={()=>setDropDown(!dropDown)}>Services</label>
                            {dropDown &&
                            <ul className="drop-menu">
                                <li><NavLink to="/services/utility">Utility Monitoring</NavLink></li>
                                <li><NavLink to="/services/energy">Energy Monitoring</NavLink></li>
                                <li><NavLink to="/services/Predictive&Preventive-maintenance">Predictive Analysis & Preventive Maintenance</NavLink></li>
                                <li><NavLink to="/services/Production-monitoring">Production Monitoring</NavLink></li>
                            </ul>}
                        </li>
                        <li><NavLink to="/about">About</NavLink></li>
                        <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                        <li><button to="#" className="nav-button signIn" onClick={() => window.open('https://demo-apps.sitesenz.com/web-app/#/user/login', '_blank')}>Sign in </button></li>
                        <li><button to="#" className="nav-button" onClick={()=>navigate('/contact-us')}>Free Trail </button></li>
                    </ul>
           </div>
        </div>
        
    );
}

export default Header;
