import "./Styles/utility.css"
import Blob1 from "../../../assets/Svg/blob1.svg"
import Banner1 from "../../../assets/Image/banner1.png"
import Banner from "../../../assets/SiteSenz_Images/Utility Monitoring/Banner/2011.i402.055..electricity-and-lighting-flat-icons-02.webp"
import UBanner from "../../../assets/Image/ServicesImages/UtilityMonitoring/Banners/2011.i402.055..electricity and lighting flat icons-02.svg"
import { useState,useEffect } from "react"
function Hero(){
    const [showAnimate, setShowAnimate] = useState(false);

    useEffect(() => {
        // Add the class after a short delay to trigger the animation
        const timer = setTimeout(() => {
            setShowAnimate(true);
        }, 100); // Adjust the delay as needed
        return () => clearTimeout(timer);
    }, []);
    return(
        <div class="banner-container">
            <div className="banner-inner-container">
                <div className={`banner-content-container ${showAnimate ? 'show-animate' : ''}`}>
                    <div className="heading animate">
                    Real-Time Utility  Monitoring System
                    </div>
                    <div className="short-heading animate">
                    This innovative system combines the power of Internet of Things (IoT) sensors & meters with intelligent edge gateways for real-time data collection and analysis of various utilities.
                    Sensors & meters strategically placed throughout your facility as per the requirement to capture data on Energy,Water,Gas,Compressed Air and Steam
                    </div>
                
                </div>
                <div className="banner-image-contaier">
                    <img src={Blob1} className="blob-image"/>
                    <img  src={UBanner} className="utility-banner-image" />
                </div>
               
            </div>  
          
        </div>
    )
}
export default Hero