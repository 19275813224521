import MNES from "./Component/About/aboutMNES"
import Header from "./Component/common/Header"
import Footer from "./Component/common/Footer"
import SiteSenz from "./Component/About/aboutSite"
import Hero from "./Component/About/Hero"
import MechSenz from "./Component/About/aboutMech"
function AboutUs(){
    return(
        <div>
            <Header />
            <Hero />
            <SiteSenz />
            {/* <MNES />
            <MechSenz /> */}
            <Footer />
        </div>
    )
}
export default AboutUs