import "./Style/aboutMNES.css"
function About(){
    return(
        <div className="about-us">
        <section className="intro">
          <h1> MNES</h1>
          <h3> Where Engineering Meets Innovation</h3>
          <p>At MNES, we're more than just an engineering solutions company. We're a team of passionate innovators dedicated to empowering businesses with the tools and expertise they need to succeed in the digital age. We achieve this through our comprehensive Senzify platform, a suite of industry-leading solutions designed to optimize your operations at every level.</p>
        </section>
       <div className="senzify-container">
            <section className="senzify senzigfy-content">
            <h2>Senzify : Your One-Stop Shop for Industrial Optimization</h2>
            <p>Senzify encompasses three powerful verticals, each catering to a specific stage within your manufacturing process</p>
            <div className="senzify-vertical">
				<div className="vertical">
					<h3>MachSenz</h3>
					<ul>
					<li>Focuses on the machine level, offering solutions like virtual commissioning with digital twins, machine monitoring, and a centralized software platform for managing all your machines and their documentation.</li>
					</ul>
				</div>
		
				<div className="vertical">
					<h3>SiteSenz</h3>
					<ul>
					<li>Tailored for the site or plant level, SiteSenz provides real-time monitoring and predictive analytics software, allowing you to anticipate and address potential issues before they impact production.</li>
					</ul>
				</div>
		
				<div className="vertical">
					<h3>SimuSenz</h3>
					<ul>
					<li>Specializes in simulation studies, leveraging industry-standard tools like Siemens Tecnomatix Plant Simulation and Flexsim. We can also integrate additional tools based on your specific needs.</li>
					</ul>
				</div>
			</div>
            </section>
            <div className="senzify-ex-partners">
                <section className="expertise">
                <h2>Beyond the Technology: Our Expertise Sets Us Apart</h2>
                <p>While Senzify offers the cutting-edge tools you need, it's our team of highly skilled professionals that truly makes the difference. We possess a deep understanding of your industry's challenges and a steadfast commitment to engineering excellence. Our passion for innovation fuels our dedication to finding the perfect solutions for your unique requirements.</p>
                </section>
        
                <section className="partnership">
                <h2>Partnering for Success</h2>
                <p>At MNES, we believe in building strong partnerships with our clients. We work collaboratively to understand your goals and develop customized solutions that unlock new possibilities and propel you towards unprecedented success. Whether you're in industrial machinery & equipment, pharmaceuticals, food & beverage, packaging, or any other process-driven industry, MNES and Senzify are your trusted partners for a brighter, more efficient future.</p>
                </section>
            </div>
        </div>
      </div>
    );
  };
export default About