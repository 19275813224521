let domain = "https://mnengineeringsolution.webhook.office.com/"
let hook = "webhookb2/"
let tok1 = "72da352c-9874-4808-9051-ddbedacb488c@add1ba79-1e6a-45b2-b7c7-07860fc8695e/"
let tok2="IncomingWebhook/"
let tok3="bffe1ddde9eb44588c47dd2c354a1127/"
let tok4="3a155ad1-91da-46d6-9b22-3c316bc96247"
export default {
    domain: domain,
    token: hook + tok1 + tok2 + tok3 + tok4,
  };
// url = 'https://mnengineeringsolution.webhook.office.com/webhookb2/72da352c-9874-4808-9051-ddbedacb488c@add1ba79-1e6a-45b2-b7c7-07860fc8695e/IncomingWebhook/bffe1ddde9eb44588c47dd2c354a1127/3a155ad1-91da-46d6-9b22-3c316bc96247'