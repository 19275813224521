import Blob1 from "../../assets/Svg/blob1.svg"
import Banner1 from "../../assets/Image/banner1.png"
import "./Style/aboutSite.css"
import about from "../../assets/Image/about.webp"
function Hero(){
    return(
        <div class="banner-container">
        <div className="banner-inner-container">
            <div className="banner-content-container">
                <div className="heading about-main-heading">
                        Welcome to <span className="site-title">SiteSenz</span>
                </div>
                <div className="short-heading about-short-heading">
                        Real-Time Visibility, Predictive Power
                </div>
                <p className="about-main-para"> SiteSenz, a powerful vertical of the Senzify platform, empowers you with real-time monitoring and predictive analytics capabilities. 
                    We provide the tools and industry expertise you need to gain complete control over your plant operations, 
                    optimize production efficiency, and achieve unparalleled uptime.
                </p>
            </div>
            <div className="banner-image-contaier">
                {/* <img src={Blob1} className="blob-image"/> */}
                <img  src={about} className="banner-image about-image" />
            </div>
        </div>  
    </div>
    )
}
export default Hero