import React from "react";
import Alert1 from "../../../assets/SiteSenz_Images/Energy Monitoring/Key Features/Customizable-Alerts.webp"
import Historical from "../../../assets/SiteSenz_Images/Utility Monitoring/Key Features/Historical Analysis.webp"

import "../Utility/Styles/utility.css"
import "./Styles/Energybenefit.css"
import Data from "../../../assets/SiteSenz_Images/Energy Monitoring/Key Features/Detailed Data Visualization.webp"
import Dashboard from "../../../assets/SiteSenz_Images/Utility Monitoring/Key Features/Customizable Dashboard.webp"
import Peak from "../../../assets/SiteSenz_Images/Energy Monitoring/Key Features/peek demand.webp"
import Efficiency from "../../../assets/SiteSenz_Images/Energy Monitoring/Key Features/Energy-Efficiency-Recommendations-.webp"
import { useInView } from 'react-intersection-observer';
function Blogs() {
	const { ref, inView } = useInView({
        triggerOnce: true, // Trigger only once when the component comes into view
        threshold: 0.1 // Adjust this as necessary
    });
  return (
    <div className="key-container utility-key-container">
		<h1 className="utility-key-features-title title_lines">Our Real-Time Energy Monitoring System Provides</h1>
		<div className={`key-inner-container  energy-ben-fade-up ${inView ? 'visible' : ''}` } ref={ref}>
			<div class="cards">   
			<div className="energy-card-image">
				<img 
					src={Data } alt="Avatar" width='100%' className="data-img"
				/>
				</div>
				<div class="containers">
					<h4 className="energy-key-title">DETAILES DATA VISUALIZATION</h4> 
					<p>Track energy usage by circuit, equipment, or department through user-friendly dashboards</p> 
				</div>
			</div>
			<div class="cards">   
			<div className="energy-card-image">
				<img 
				src={Dashboard } alt="Avatar" width='100%' className="dashboard"
				/>
				</div>
				<div class="containers">
					<h4 className="energy-key-title">CUSTOMIZABLE DASHBOARD</h4> 
					<p>Tailor-made dashboards allow you to visualize energy usage patterns, trends, and anomalies for actionable insights</p> 
				</div>
			</div>
			<div class="cards">
				<div className="energy-card-image">    
				<img 
					src={Peak } alt="Avatar" width='100%' className="peak-img"
				/>
				</div> 
				<div class="containers">
					<h4 className="energy-key-title">PEAK DEMAND MANAGEMENT</h4> 
					<p>Monitor peak demand periods and implement strategies to reduce peak demand charges and optimize usage</p> 
				</div>
			</div>
			
			<div class="cards">
				<div className="energy-card-image">         
				<img  
					src={Efficiency } alt="Avatar" width='100%' className="efficiency-img"
				/>
				</div> 
				<div class="containers">
					<h4>ENERGY EFFICIENCY</h4> 
					<p>Receive actionable recommendations for improving energy efficiency, reducing wastage, and lowering operational costs</p> 
				</div>
			</div>
			
			<div class="cards">   
				<div className="energy-card-image">   
				<img 
				src={Alert1 } alt="Avatar" width='100%' className="alert-img"
				/>
				</div> 
				<div class="containers custom-alert-container">
					<h4>CUSTOMIZABLE ALERT</h4> 
					<p>Receive instant notifications for abnormal energy consumption or potential equipment issues</p> 
				</div>
			</div>
			<div class="cards">  
			<div className="energy-card-image">    
				<img 
				src={Historical } alt="Avatar" width='100%' className="history-img"
				/>
				</div> 
				<div class="containers">
					<h4 className="energy-history-title">HISTORICAL DATA ANALYSIS</h4> 
					<p>Gain insights into trends and identify areas for long-term energy savings strategies</p> 
				</div>
			</div>
		</div>
    </div>
  );
}

export default Blogs;
