import { BrowserRouter, Routes, Route, Navigate,HashRouter,useLocation  } from "react-router-dom";
import { useEffect } from "react";
import App from "./App";
import Contact from "./Contact";
import About from "./AboutUs"
import Utility from "./Component/Services/Utility/Utility";
import Predictive from "./Component/Services/Predictive/Predictive"
import Energy from "./Component/Services/Energy/energy"
import Production from "./Component/Services/Production/production";
// import ReactGA from "react-ga"
import ReactGA from 'react-ga4';
// const TRACKING_ID = "G-HL6F635HJQ"
// ReactGA.initialize(TRACKING_ID);
const TrackPageView = () => {
    const location = useLocation();
  
    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);
  
    return null;
  };
const RouteSetting = () => (
      <HashRouter >
        <TrackPageView />
        <Routes>
          <Route exact path="/" element={<Navigate replace to="/home" />} />
          <Route  path="/home" element={<App />} />
          <Route  path="/contact-us" element={<Contact />} />
          <Route  path="/about" element={<About />} />
          <Route  path="/services/utility" element={<Utility />} />
          <Route  path="/services/Predictive&Preventive-maintenance" element={<Predictive />} />
          <Route  path="/services/energy" element={<Energy />} />
          <Route  path="/services/Production-monitoring" element={<Production />} />
        </Routes>
      </HashRouter>
    );
  
  
  export default RouteSetting;