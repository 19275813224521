import "./Style/aboutSite.css"

import DropDown from "./Dropdown";
const Granular =[
    {
        id:1,
        question:"Granular Visibility",
        answer:"Gain a real-time, data-driven perspective of your entire production process, from individual machines to complete production lines"
    },
    {
        id:2 ,
        question:"Actionable Insights",
        answer:"Transform raw data into actionable insights that empower informed decision-making and proactive problem-solving"
    },
    {
        id:3 ,
        question:"Enhanced Situational Awareness",
        answer:"Monitor key performance indicators (KPIs) in real-time, allowing you to identify and address potential issues before they disrupt production"
    }
]
const  predictive = [
    {
        id:4,
        question:"Anticipate Equipment Failure",
        answer:"Identify potential equipment problems well in advance, allowing you to schedule preventive maintenance and minimize downtime."
    },
    {
        id:5,
        question:"Optimize Maintenance Strategies",
        answer:"Shift from reactive maintenance to a proactive approach, maximizing equipment lifespan and reducing overall maintenance costs"
    },
    {
        id:6,
        question:"Boost Production Efficiency",
        answer:"By predicting and preventing disruptions, SiteSenz helps you streamline your production process and achieve peak efficiency"
    }
]
const  expertise = [
    {
        id:7,
        question:"Understand Your Unique Needs",
        answer:"We take the time to assess your specific plant operations and challenges."
    },
    {
        id:8,
        question:"Develop a Customized Solution",
        answer:"Our team tailors the SiteSenz platform to perfectly integrate with your existing infrastructure and workflows."
    },
    {
        id:9,
        question:"Ongoing Support",
        answer:"We offer continuous support to ensure you get the most out of SiteSenz, maximizing its impact on your operations"
    }
]
function AboutSite(){
    return(
        <div className="about-site-container">
            <div className="about-site-inner-container ">
               
                <div className="analytics-container">
                    <div className="Power-container">
                        <div className="power-main-title">
                            Unleash the Power of Real-Time Data
                        </div>
                        <div className="power-sub-title">SiteSenz goes beyond traditional monitoring systems. Our comprehensive suite offers:</div>
                        <DropDown faqs={Granular}/>
                    </div>
                   
                    <div className="expertise-container">
                        <div className="expertise-main-title">Expertise You Can Trust </div>
                        <div className="expertise-sub-title">Our team of industry experts goes beyond simply providing software. We partner with you to: </div>
                            <DropDown faqs={expertise} />
                        </div>
                </div>
                <div className="analytics-container">
                <div className="predictive-container">
                        <div className="predictive-main-title">Predictive Analytics: The Future of Industrial Optimization </div>
                        <div className="predictive-sub-title">SiteSenz anticipates problems with predictive analytics. Our advanced engine uses machine learning to: </div>
                        <DropDown faqs={predictive}/>
                       
                    </div>
                    <div className="partner-container">
                        <div className="partner-main-title">Partner with SiteSenz for Unmatched Production Visibility and Control</div>
                        <p> At SiteSenz, we are committed to empowering you to achieve operational excellence. Our real-time monitoring and predictive 
                            analytics solutions provide the visibility and control you need to optimize production, minimize downtime, 
                            and achieve your profitability goals.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default AboutSite;