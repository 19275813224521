import Header from "../../common/Header"
import Footer from "../../common/Footer"
import "./Styles/energy.css"
import Benefit from "../Energy/energyBenefit"
import EnergyImg from "../../../assets/Image/Services/energy.png"
import EnergyInvest from "../../../assets/Image/Services/energyInvest.png"
import EnergyApp from "../../../assets/Image/Services/energyApp.png"
import DataDriven from "../../../assets/Image/ServicesImages/Energy Monitoring/Icons/Make Data-Driven Decisions.svg"
import Predict from "../../../assets/Image/ServicesImages/Energy Monitoring/Icons/Predict Peak Usage.svg"
import Reduce from "../../../assets/Image/ServicesImages/Energy Monitoring/Icons/Reduce Energy Waste.svg"
import Target from "../../../assets/Image/ServicesImages/Energy Monitoring/Icons/Target Conservation Efforts.svg"
import Unmask from "../../../assets/Image/ServicesImages/Energy Monitoring/Icons/Unmask Hidden Inefficiencies.svg"
import Energybanner from "./EnergyBanner"
import { useInView } from 'react-intersection-observer';
function Energy(){
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger only once when the component comes into view
        threshold: 0.1 // Adjust this as necessary
    });
    return(
        <div className="energy-service">
        <Header />
        <Energybanner />
        <div className="energy-container">
            <div className="energy-inner-container">
                <div className="energy-icon-cards"    >
                <div className='energy-vertical'>
                        <div className="energy-data">
                            <div className="energy-key-image">
                            <img src={Unmask} className="energy-icon"/>
                            </div>
                            <div className="energy-title ">Unmask Hidden Inefficiencies</div>
                            <p className="energy-description">
                            Identify specific areas or equipment responsible for excessive energy use
                            </p>
                        </div>
		
                        <div className="energy-data">
                            <img src={Target} className="energy-icon"/>
                            <div className="energy-title">Target Conservation Efforts</div>   
                            <p className="energy-description">Focus your energy-saving strategies on the areas with the biggest impact.</p>                        
                        </div>
                
                        <div className="energy-data">
                            <img src={Predict} className="energy-icon"/>
                            <div className="energy-title">Predict Peak Usage</div>
                            <p className="energy-description">Gain insights into daily or seasonal trends, allowing you to adjust operations for lower energy consumption during peak periods</p> 
                        </div>
			    </div>
                <div className="energy-vertical2">
                        <div className="energy-data">
                            <img src={Reduce} className="energy-icon"/>
                            <div  className="energy-title">Reduce Energy Waste</div>
                            
                            <p className="energy-description">Real-time monitoring helps quickly identify and rectify issues like equipment malfunctions or standby power drain</p>
                            
                        </div>
                
                        <div className="energy-data">
                            <img src={DataDriven} className="energy-icon"/>
                            <div className="energy-title">Make Data-Driven Decisions</div>
                            <p className="energy-description">Back your energy-saving initiatives with concrete data for better results.</p>  
                        </div>
                </div>
                </div>
                <div className="predictive-ben-key">
                    <div className="energy-benefit">
                        <Benefit />
                       
                    </div>
                  
                </div>
                <div className={`energy-industry-container   `} ref={ref}>
                    <div className="energy-invest-image">
                        <div className="energy-invest-img">
                         <img src={EnergyApp}/>
                        </div>
                        <div className="energy-main-title">
                            <p className="energy-invest-title">Industry Applications</p>
                            <p className="energy-invest-content"> Our energy monitoring solutions are suitable for a wide range of industries, including manufacturing, automotive, pharmaceuticals, 
                            food and beverage, and more. 
                            Whether you're managing a single facility or a multi-site operation, our scalable solutions can meet your unique energy monitoring needs. </p>
                        </div>
                    </div>
                    <div className="energy-invest-image1">
                        <div className="energy-invest-img">
                         <img src={EnergyInvest}/>
                        </div>
                        <div className="energy-main-title">
                            <p className="energy-invest-title">Invest in a Sustainable Future</p>
                            <p className="energy-invest-content"> By optimizing your energy consumption, you not only save money on utility bills, but also contribute to a greener environment.
                                Real-time energy monitoring empowers you to make informed decisions that promote sustainability for your facility. </p>
                        </div>
                    </div>
                      
                </div>
            </div>
        </div>
        <Footer />
        </div>
    )
}
export default Energy