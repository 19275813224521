import React from "react";
import "../../Styles/FeatureItem.css"
import Insights from "../../assets/SiteSenz_Images/Home Page/Key Features/Insights.webp"
import Alert1 from "../../assets/SiteSenz_Images/Home Page/Key Features/Alert.webp"
import Historical from "../../assets/SiteSenz_Images/Home Page/Key Features/Historical.webp"
import KPI from "../../assets/SiteSenz_Images/Home Page/Key Features/KPI.webp"
import Quality from "../../assets/SiteSenz_Images/Home Page/Key Features/Quality.webp"
import Scalablity from "../../assets/SiteSenz_Images/Home Page/Key Features/Scalablity.webp"
import Productionrate from "../../assets/SiteSenz_Images/Home Page/Key Features/Productionrate.webp"
import { useInView } from 'react-intersection-observer';
function Blogs() {
	const { ref, inView } = useInView({
        triggerOnce: true, 
        threshold: 0.1 
    });
  return (
    <div className="key-container">
		<h1 className="title_lines">Key Features of the Product</h1>
		<div className={`key-inner-container home-key-fade-up ${inView ? 'visible' : ''}`} ref={ref}>
        <div class="cards">
			<div className="home-card-img">
				<img src={Insights } alt="Avatar" width="100%"  className="insight-img" />
			</div>
          <div class="containers">
            <h4>INSIGHTS</h4> 
            <p>Provides instant visibility into machine performance, site operations, and simulation results</p> 
          </div>
        </div>
		<div class="cards">
			<div className="home-card-img">
			<img src={Scalablity } alt="Avatar" width='100%' className="insight-img" />
			</div>
          <div class="containers">
            <h4>SCALABLITY</h4> 
            <p>Adapt the platform to your specific needs, from single machines to complex factory ecosystems</p> 
          </div>
        </div>
		<div class="cards">
			<div className="home-card-img">
			<img src={KPI } alt="Avatar" width='100%' className="home-key-kpi" />
			</div>
          <div class="containers">
            <h4 className="monitor-key-head">MONITOR API KEY</h4> 
            <p>Monitors every KPI you crave, from production speed to perfect quality, in real time</p> 
          </div>
        </div>
		<div class="cards">
			<div className="home-card-img">
			<img src={Productionrate } alt="Avatar" width='100%' className="insight-img" />
			</div>
          <div class="containers">
            <h4 className="production-rate-head">PRODUCTION RATE</h4> 
            <p>Shows the production rate of each machine, allowing supervisors to track and adjust production levels as needed</p> 
          </div>
        </div>
		<div class="cards cardHigh">
			<div className="home-card-img">
			<img src={Alert1 } alt="Avatar" width='100%' className="home-key-alert" />
			</div>
          <div class="containers">
            <h4 className="home-key-alert-title">ALERT & NOTIFICATION</h4> 
            <p>Sends real-time alerts to operators and managers when a machine is down or operating outside optimal parameters</p> 
          </div>
        </div>
		<div class="cards cardHigh">
			<div className="home-card-img">
			<img src={Quality } alt="Avatar" width='100%' className="insight-img" />
			</div>
          <div class="containers">
            <h4 className="home-key-quality-title">QUALITY METRICES</h4> 
            <p>Monitors quality control metrics, such as defect rates and rejected parts, to ensure product quality and minimize defects</p> 
          </div>
        </div>
		<div class="cards cardHigh">
			<div className="home-card-img">
			<img src={Historical } alt="Avatar" width='100%' className="insight-img" />
			</div>
          <div class="containers">
            <h4 className="home-key-history-title">HISTORICAL ANALYTICS & REPORT</h4> 
            <p>Access historical data trends to identify patterns, make informed decisions, and implement process improvements for long-term success</p> 
          </div>
    </div>
		</div>
    </div>
  );
}

export default Blogs;
