import Blob1 from "../../../assets/Svg/blob1.svg"
import Banner from "../../../assets/SiteSenz_Images/Home Page/20943761.webp"
import { useState,useEffect } from "react"
function Energybanner(){
    const [showAnimate, setShowAnimate] = useState(false);

    useEffect(() => {
        // Add the class after a short delay to trigger the animation
        const timer = setTimeout(() => {
            setShowAnimate(true);
        }, 100); // Adjust the delay as needed
        return () => clearTimeout(timer);
    }, []);
    return(
        <div class="banner-container">
            <div className="banner-inner-container">
                <div className={`banner-content-container ${showAnimate ? 'show-animate' : ''}`}>
                    <div className="heading animate">
                    Predictive Maintenance
                    </div>
                    <div className="short-heading animate">
                    Predictive maintenance utilizes data analytics to predict equipment failures before they occur. 
                        By analysing machine sensor data and historical trends, SiteSenz identifies early warning signs of potential issues, empowering you to
                  
                    </div>
                    
                </div>
                <div className="banner-image-contaier">
                    {/* <img src={Blob1} className="blob-image"/> */}
                    <img 
                    src={Banner} className="energy-banner-image"
                    />
                    {/* <img  src={Banner} className="energy-banner-image" /> */}
                </div>
               
            </div>  
          
        </div>
    )
}
export default Energybanner