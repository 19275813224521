import "./Styles/Form.css"
import { useEffect,useState,useRef } from "react";
import * as functions from "../common/validateFunction";
import variables  from "../common/Global"
function Form(){
    const [form, setForm] = useState({
        name:"",
        email:"",
        phone:"",
        company:"",
        designation:"",
        requirement:""
      });
    const [errorName, setErrorName] = useState("");
    const [validName,setValidName] = useState(true)
    const [validEmail,setValidEmail] = useState(true)
    const [errorEmail,setErrorEmail] = useState('')
    const [validPhone,setValidPhone] = useState(true)
    const [errorPhone,setErrorPhoneNumber] = useState("")
    const [validCompany,setValidCompany]= useState(true)
    const [errorCompany,setErrorCompanycode] = useState("")
    const [validDesignation,setValidDesignation] = useState(true)
    const [errorDesignation,setErrorDesignation] = useState("")
    const [validRequirement,setValidRequirement] = useState(true)  
    const [errorRequirement,setErrorRequirement] = useState("")
    const [success,setSuccess] = useState("")
    const [error,setError] = useState("")
    const handleSubmit=(event)=>{
        event.preventDefault()
        if (
            !functions.validateInput(
              form.name.trim(),
              setErrorName,
              "alphabetic"
            )
          ) {
            return setValidName(false);
          }
        if (!functions.validateInput(form.email, setErrorEmail, "email")) {
            return setValidEmail(false);
        }
        if (
            !functions.validateInput(
              form.phone,
              setErrorPhoneNumber,
              "nonalphabet",
              false
            )
          ) {        
            return setValidPhone(false);
        }
        if (
            !functions.validateInput(
                form.company.trim(),
              setErrorCompanycode,
              "alphanumeric"
            )
          ) {
            return setValidCompany(false);
        }
        if (
            !functions.validateInput(
                form.designation.trim(),
              setErrorDesignation,
              "alphanumeric"
            )
          ) {
            return setValidDesignation(false);
        }
       
        if(form.phone.length<1){
            setErrorPhoneNumber("Please enter phone number")
        }
        if(form.requirement.length<1){
            setErrorRequirement("Please enter requirement")
        }
        if(validName && validEmail && validPhone && validCompany && validDesignation && validRequirement){
        // const myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/json");
        // myHeaders.append("Access-Control-Allow-Origin", "http://localhost:3000");
        
        const raw = JSON.stringify({
        "type": "message",
        "attachments": [
            {
            "contentType": "application/vnd.microsoft.card.adaptive",
            "contentUrl": null,
            "content": {
                "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
                "type": "AdaptiveCard",
                "version": "1.5",
                "body": [
                {
                    "type": "ColumnSet",
                    "columns": [
                    {
                        "type": "Column",
                        "width": 2,
                        "items": [
                        {
                            "type": "TextBlock",
                            "text": `${form.name}\n`,
                            "weight": "Bolder",
                            "size": "ExtraLarge",
                            "spacing": "None",
                            "wrap": true,
                            "style": "heading",
                            "horizontalAlignment": "Left",
                            "fontType": "Default",
                            "isSubtle": false
                        },
                        {
                            "type": "TextBlock",
                            "text": "\n **____________________________________** \n",
                            "wrap": true,
                            "spacing": "None",
                            "horizontalAlignment": "Left",
                            "fontType": "Monospace",
                            "size": "Small",
                            "weight": "Bolder"
                        },
                        {
                            "spacing": "Small",
                            "type": "TextBlock",
                            "text": `**Desigination & Company :** ${form.designation} `,
                            "wrap": true
                        },
                        {
                            "type": "TextBlock",
                            "text": `**Email :** ${form.email}`,
                            "wrap": true
                        },
                        {
                            "type": "TextBlock",
                            "text": `**Phone :** ${form.phone}`,
                            "wrap": true
                        },
                        {
                            "type": "TextBlock",
                            "text": `**Requirement :** ${form.requirement} `,
                            "size": "Small",
                            "wrap": true,
                            "maxLines": 10
                          }
                        ]
                    }
                    ]
                }
                ]
            }
            }
        ]
        });

        // fetch('https://mnengineeringsolution.webhook.office.com/webhookb2/72da352c-9874-4808-9051-ddbedacb488c@add1ba79-1e6a-45b2-b7c7-07860fc8695e/IncomingWebhook/bffe1ddde9eb44588c47dd2c354a1127/3a155ad1-91da-46d6-9b22-3c316bc96247', {
        fetch(variables.domain+variables.token,{  
        method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            mode: 'no-cors',
            // body: JSON.stringify(data),
            body:raw
          })
          .then((response) => {
            setForm({
                name:"",
                email:"",
                phone:"",
                company:"",
                designation:"",
                requirement:""
            })
            setSuccess("Thank you for reaching out to us! We have received your message and will get back to you soon.")
            setTimeout(() => setSuccess(" "), 5000); 
             }
          )
          .catch((error) => {
            setError(error)
            setTimeout(() => setError(" "), 5000); 
          });
          
        }
    }
    const InputHandler = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const updatedForm = {
          ...form,
          [name]: value,
        };
        setForm(updatedForm);
      };
      console.log(validName)
    return(
        <div className="contact-container">
          <form className="form-container" onSubmit={handleSubmit}>
            <p className="sucsess">{success}</p>
            <p className="error">{error}</p>
            <div className="form-inner-container">
                <h1>Contact Us</h1>
                <p>Please take a moment to get in touch, we will get back to you shortly.</p>

                <div class="column">
                    <label for="the-name">Name <span className="error">{errorName?errorName:""}</span></label>
                    <input 
                        type="text" 
                        name="name" 
                        id="the-name" 
                        value={form.name}
                        onChange={(event)=>{
                            InputHandler(event);
                            setValidName(true);
                            setErrorName("")
                        }}
                        className={!validName?"invalid-error":""}
                    />
                    
                    <label for="the-email">Email Address <span className="error">{errorEmail?errorEmail:""}</span></label>
                    <input 
                        type="email" 
                        name="email" 
                        id="the-email" 
                        value={form.email}
                        onChange={(event)=>{
                            InputHandler(event);
                            setValidEmail(true);
                            setErrorEmail("")
                        }}
                        className={!validEmail?"invalid-error":""}
                    />
                    <label for="the-phone">Phone Number <span className="error">{errorPhone?errorPhone :""}</span></label>
                    <input 
                        type="tel" 
                        name="phone" 
                        id="the-phone"   
                        value={form.phone}
                        onChange={(event)=>{
                            InputHandler(event);
                            setValidPhone(true);
                            setErrorPhoneNumber("")
                        }}
                        className={!validPhone?"invalid-error":""}
                    />
                    <label for="the-phone">Company Name <span className="error">{errorCompany?errorCompany:""}</span></label>
                    <input 
                        type="tel" 
                        name="company" 
                        id="the-phone"   
                        value={form.company}
                        onChange={(event)=>{
                            InputHandler(event);
                            setValidCompany(true);
                            setErrorCompanycode("")
                        }}
                        className={!validCompany?"invalid-error":""}
                    />                
                </div>
                <div class="column">               
                    <label for="the-phone">Designation <span className="error">{errorDesignation?errorDesignation:""}</span></label>
                    <input 
                        type="tel" 
                        name="designation" 
                        id="the-phone"   
                        value={form.designation}
                        onChange={(event)=>{
                            InputHandler(event);
                            setValidDesignation(true);
                            setErrorDesignation("")
                        }}
                        className={!validDesignation?"invalid-error":""}
                    />
                    <label for="the-message">Requirement <span className="error">{errorRequirement?errorRequirement:""}</span></label>
                    <textarea 
                        name="requirement" 
                        id="the-message"   
                        value={form.requirement}
                        onChange={(event)=>{
                            InputHandler(event);
                            setValidRequirement(true);
                            setErrorRequirement("")
                        }} 
                        className={!validRequirement?"invalid-error":""}
                    />             
                    <input type="submit" value="Send Message" />
                </div>
                </div>
            </form>
            
        </div>
    )
}
export default Form