import React, { useState } from 'react';
import '../../Styles/QA.css'; // Import CSS file for styles

const FAQItem = ({ faq, isOpen, toggleAccordion }) => (
    <div className={`faq-item ${isOpen ? 'open' : ''}`}>
      <div className="faq-question" onClick={toggleAccordion}>
        <div >{faq.question}</div>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      <p className="faq-answer">{faq.answer}</p>
    </div>
  );
  

const FAQList = ({ faqs ,ques}) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className='faq-inner'>
        <div className="faq-list">
        {faqs.map((faq, index) => (
            <FAQItem
            key={faq.id}
            faq={faq}
            isOpen={openIndex === faq.id}
            toggleAccordion={() => toggleAccordion(faq.id)}
            />
        ))}
        </div>
        <div className="faq-list2">
        {ques.map((faq, index) => (
            <FAQItem
            key={faq.id}
            faq={faq}
            isOpen={openIndex === faq.id}
            toggleAccordion={() => toggleAccordion(faq.id)}
            />
        ))}
        </div>
    </div>
  );
};

const FAQPage = () => {
  const faqs = [
    {
        id:1,
        question: "How do I implement SiteSenz in my production?",
        answer:
          "Identify the lines/machines you want to implement SiteSenz and take a list of control systems used in. Our experts will guide you to proceed further."
      },
      {
        id:2,
        question: "What hardware is required for integrating SiteSenz to \n production line?",
        answer:
          "No need of any additional hardware to implement SiteSenz. SiteSenz comes with edge gateway which can communicate with machine, production lines and smart devices. Connect with our expertise for further details."
      },
      {
        id:3,
          question:"How to connect my machines and production lines to SiteSenz?",
          answer:"SiteSenz comes with edge gateway, which can fetch the data from your machine’s / line’s control system. Connect with our expertise for further details."
      },
      {
          id:4,
          question:"Will I have a dedicated person supporting me?",
          answer:"Yes. We have a dedicated team to support SiteSenz product."
      },
      {
        id:5,
        question:"What is the lead time for setting up SiteSenz?",
        answer:"It depends upon the number of machines or production lines and customization you want to implement in SiteSenz. Connect with our expertise for further details."
    },
    {
        id:6,
        question:"What other hardware related cost is incurred with \n SiteSenz?",
        answer:"No other hardware related cost other than Edge Gateway and SiteSenz customization cost if any."
    },
    {
        id:7,
        question:"Can I cancel my subscription? Before completing the period \n of subscription?",
        answer:"Yes, you can cancel your subscription at any point of time."
    },
   
  ];
  const ques =[
    {
        id:8,
        question:"Can SiteSenz data be exported or integrated with other platforms?",
        answer:"Yes, you can integrate data with other platforms. Connect with our expertise for further details."
      },
    {
      id:9,
        question:"What type of data storage options are available?",
        answer:"We are offering two types of storage options, Cloud based data storage (AWS/Azure/GCP) ,On premise (Client own and client-maintained server)"
    },
    {
        id:10,
        question:"For how long can or how many years will the data be stored  \n and maintained?",
        answer:"It depends upon the type of storage you choose. Connect with our expertise for further details."
    },
    {
        id:11,
        question:"Can our existing systems (MES, ERP, SCADA) be integrated \n with the monitoring system?",
        answer:"Yes, you can integrate SiteSenz with other system. Connect with our expertise for further details."
    },
    {
      id:12,
        question:"Are there specific metrics or standard monitoring KPI’s,what \n are they,can it be customised if so to what extent?",
        answer:"OEE, availability, performance & Quality are our standard KPI’s. We can customise to your use case. Connect with our expertise for further details."
    },
    {
      id:13,
        question:"How many users can use the monitoring system at the same \n time?",
        answer:"There is no user limitation to use the monitoring system at the same time. But one user can log in one device at a time."
    },
    {
      id:14,
        question:"can we have different metrics for different groups of users?",
        answer:"Yes. You can customize the user groups also"
    },
    ]
  return (
    <div className="faq-page">
      <h1 className=' title_lines'>Frequently Asked Questions</h1>
      <FAQList faqs={faqs} ques={ques}/>
    </div>
  );
};

export default FAQPage;
