import Header from "../../common/Header";
import Footer from "../../common/Footer";
import "./Styles/utility.css"
// import Keyfeature from "../../assets/Image/KeyFeatures/keyFeaure.png"
import DropDown from "../../About/Dropdown";
import ServiceKey from "./serviceKey"
import WhyService from "./WhyService"
import Banner from "./UtilityBanner"
import Energy from "../../../assets/Image/ServicesImages/UtilityMonitoring/Icons/Energy (Electrical).svg"
import Air from "../../../assets/Image/ServicesImages/UtilityMonitoring/Icons/Compressed Air.svg"
import Gas from "../../../assets/Image/ServicesImages/UtilityMonitoring/Icons/Gas.svg"
import Steam from "../../../assets/Image/ServicesImages/UtilityMonitoring/Icons/Steam.svg"
import Water from "../../../assets/Image/ServicesImages/UtilityMonitoring/Icons/Water.svg"
import Benefits from "./UtilityBenefits";
import { useState,useRef,useEffect } from "react";
import { useInView } from 'react-intersection-observer';
function Utility(){
    const [isVisible, setIsVisible] = useState(false);
  
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger only once when the component comes into view
        threshold: 0.1 // Adjust this as necessary
    });

    return(
        <div className="utility-service">
            <Header/>
            <Banner />
   
            <div className="utility-container">
               <div className="utility-inner-container">
                  <div className="icon-cards">
                    <div 
                      className='utility-vertical'
                    >
                        <div className={`utility-data fade-up ${inView ? 'visible' : ''}`}  ref={ref} >
                            <div className="utility-flex">
                                <img src={Energy} className="utility-icon"/>
                                <div className="utility-data-title">Energy (Electrical)</div>
                            </div>
                            <p className="utility-data-description">
                                    Monitor power consumption, voltage fluctuations, peak demand periods, power factor, 
                                    and power quality to enhance efficiency and reduce wastage.
                            </p>
                        </div>
		
                        <div className={`utility-data fade-up ${inView ? 'visible' : ''}`}  ref={ref} >
                            <div className="utility-flex">
                                <img src={Water} className="utility-icon"/>
                                <div className="utility-data-title">Water</div> 
                            </div>  
                            <p className="utility-data-description">Track water usage, detect leaks, and optimize usage patterns for sustainable water management and cost reduction, Water flow, pressure levels.</p>                        
                        </div>
                
                        <div  className={`utility-data fade-up ${inView ? 'visible' : ''}`}  ref={ref}>
                            <div className="utility-flex">
                                <img src={Gas} className="utility-icon"/>
                                <div className="utility-data-title">Gas</div>
                            </div>
                            <p className="utility-data-description"> Monitor gas consumption, ensuring optimal usage and safety compliance, potential leaks</p>
                        
                        </div>
			        </div>
                    <div className="utility-vertical2">
                        <div  className={`utility-data fade-up ${inView ? 'visible' : ''}`}  ref={ref}>
                            <div className="utility-flex">
                                <img src={Air} className="utility-icon"/>
                                <div  className="utility-data-title">Compressed Air</div>
                            </div>
                            <p className="utility-data-description"> Analyse compressed air usage, Air pressure, identify leaks or inefficiencies, and improve overall system performance for energy savings</p>
                            
                        </div>
                
                        <div  className={`utility-data fade-up ${inView ? 'visible' : ''}`}  ref={ref}>
                            <div className="utility-flex">
                                <img src={Steam} className="utility-icon"/>
                                <div className="utility-data-title">Steam</div>
                            </div>
                            <p className="utility-data-description">Steam pressure, temperature, condensate levels, Measure steam generation and distribution, optimize boiler efficiency, and prevent energy losses through real-time insights</p>
                           
                        </div>
                    </div>
                    </div>
                    <ServiceKey />
                    <div className={`utility-key-title title_lines`}>Benefits of Real-Time Utility Monitoring</div>
                    <div className="utility-answer-container">Our real-time utility monitoring system is trusted by industries such as manufacturing, pharmaceuticals, food and beverage, 
                        and more. By leveraging our technology, businesses can:</div>
                    <Benefits />
              
                
                    <div className="utility-ex-part">
                        <div className="invest">
                            <div className="invest-title">Invest in a Sustainable Future</div>
                            <p>By optimizing your utility consumption, you not only save money on utility bills, but also contribute to a greener environment. 
                                Real-time energy monitoring empowers you to make informed decisions that promote sustainability for your facility. </p>
                        </div>
                        <div className="control">
                            <div className="control-title">Ready to Take Control of Your Utilities?</div>
                            <p>A real-time utility monitoring system unlocks a new level of control and efficiency for your facility. Contact us 
                                today to discuss your specific 
                                needs and explore how this innovative solution can help you optimize your resources and achieve your sustainability goals. </p>
                        </div>
                    </div>
                  
                   
               </div>
               
            </div>
            <Footer />
        </div>
    )
}
export default Utility