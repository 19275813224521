import Header from "../../common/Header";
import Footer from "../../common/Footer";
import "./Styles/predictive.css"

import caseStudy from "../../../assets/Image/Services/caseStudies.png"
import Sketch from "../../../assets/Image/Services/sketch.svg"
import Banner from "./PredictiveBanner"
import Schedule from "../../../assets/Image/ServicesImages/Predictive Maintenance & Analysis/Icons/Scheduled Maintenance Proactively.svg"
import Condition from "../../../assets/Image/ServicesImages/Predictive Maintenance & Analysis/Icons/Condition Monitoring.svg"
import Failure from "../../../assets/Image/ServicesImages/Predictive Maintenance & Analysis/Icons/Failure Prediction.svg"
import Optimize from "../../../assets/Image/ServicesImages/Predictive Maintenance & Analysis/Icons/Optimize Parts Inventory.svg"
import Extend from "../../../assets/Image/ServicesImages/Predictive Maintenance & Analysis/Icons/Extend Equipment Lifespan.svg"
import PredictiveKey from "./predictiveKey"
import Benefits from "./PredictiveBenefits";
import { useInView } from 'react-intersection-observer';
function Predictive(){
    const { ref, inView } = useInView({
        triggerOnce: true, 
        threshold: 0.1 
    });
    return(
        <div className="predictive-service">
            <Header/>
            <Banner />
            <div className="utility-container">
               <div className={`utility-inner-container predivtive-fade-up ${inView ? 'visible' : ''}`} ref={ref}>
                    <div className="predictive-vertical">
                        <div className="predictive-data">
                           
                            <img src={Schedule} alt="" className="predictive-icon"/>
                            <div className="predictive-title">Schedule Maintenance Proactively</div>
                            <p className="predictive-description">
                            Plan maintenance interventions based on predicted equipment conditions, not arbitrary schedules, minimizing downtime.
                            </p>
                        </div>
		
                        <div className="predictive-data">
                            <img src={Condition} alt="" className="predictive-icon"/>
                            <div className="predictive-title">Condition Monitoring</div>   
                            <p className="predictive-description">Monitor equipment health in real time, detect anomalies, and predict potential failures based on historical data and machine learning models.</p>                        
                        </div>
                
                        <div className="predictive-data">
                            <img src={Failure} alt="" className="predictive-icon"/>
                            <div className="utility-data-title">Failure Prediction</div>
                            <p className="predictive-description">Predict equipment failures, identify root causes, and implement corrective actions to prevent costly downtime and production interruptions</p>
                        
                        </div>
			        </div>
                    <div className="utility-vertical2">
                        <div className="predictive-data">
                            <img src={Optimize} alt="" className="predictive-icon"/>
                            <div  className="predictive-title">Optimize Parts Inventory</div>
                            
                            <p className="predictive-description">Stock only the necessary spare parts based on predicted maintenance needs, reducing inventory carrying costs</p>
                            
                        </div>
                
                        <div className="predictive-data">
                            <img src={Extend} alt="" className="predictive-icon"/>
                            <div className="predictive-title">Extend Equipment Lifespan</div>
                            <p className="predictive-description">Implement preventive maintenance strategies to prevent major breakdowns and extend the life of your equipment.</p>
                           
                        </div>
                    </div>
                    <div className="predictive-key">  
                        <PredictiveKey />
                    </div>
                    <div className="predictive-ben-key">
                        <h1 className="title_lines">Benefits of Predictive Maintenance</h1>
                            <Benefits />
                    </div>
                    
                    <div className="predictive-industry">
                    <div className="industry">
                        <div className="industry-container">
                            <div className="industry-title">Industry Applications </div>
                            <p className="industry-content">Our predictive analysis and preventive maintenance solutions are applicable across various industries, including manufacturing, energy, utilities, healthcare, and logistics. 
                                Whether you're managing complex machinery or critical infrastructure, our solutions can help you maintain operational excellence. </p>
                        </div>
                        {/* <div className="industry-img">
                           <img src={caseStudy}/>
                        </div> */}
                    </div>
                    <div className={`industry-container1 predivtive-fade-up ${inView ? 'visible' : ''}`} ref={ref}>
                            <div className="industry-title">Case Studies</div>  
                            <p className="industry-content">
                                <div className="casestudy">
                                    {/* <img src={Sketch} /> */}
                                    <p>Power Generation Plant</p>
                                </div>
                                <p> Reduced maintenance costs by 30% through predictive maintenance, equipment health monitoring, 
                                    and optimized maintenance schedules.</p>
                            </p>
                            <p className="industry-content2">
                                <div className="casestudy">
                                    {/* <img src={Sketch} /> */}
                                    <p>Healthcare Facility </p>
                                </div>
                                    <p>Improved equipment uptime by 25% by implementing predictive analysis, preventive maintenance
                                         strategies, and proactive asset management
                                </p>
                            </p>
                    </div>
                    </div>
               </div>
             
            </div>
            <Footer />
        </div>
    )
}
export default Predictive;