import "../../Styles/Service.css"
import Utility from "../../assets/Image/Services/utility1.png"
import { useNavigate } from "react-router-dom";
import UtilityBanner from "../../assets/SiteSenz_Images/Home Page/2011.i402.055..electricity-and-lighting-flat-icons-02.webp"
import EnergyBanner3 from  "../../assets/SiteSenz_Images/Home Page/6882227.webp"
import { useEffect,useState,useRef } from "react";
import Ubanner from "../../assets/Image/ServicesImages/UtilityMonitoring/Banners/2011.i402.055..electricity and lighting flat icons-02.svg"
function Service(){
    const contentRef = useRef(null);
    const content2Ref = useRef(null)
    const navigate = useNavigate();
    function scrollToTopSmoothly() {
        const scrollStep = -window.scrollY / (500 / 15); // Adjust the speed here (500 is the duration in milliseconds)
        const scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                window.scrollBy(0, scrollStep);
            } else {
                clearInterval(scrollInterval);
            }
        }, 15);
    }
    const [showAnimate, setShowAnimate] = useState(false);
    const [showAnimate2, setShowAnimate2] = useState(false);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setShowAnimate(true);
                }
            },
            { threshold: 0.1 } 
        );

        if (contentRef.current) {
            observer.observe(contentRef.current);
        }

        return () => {
            if (contentRef.current) {
                observer.unobserve(contentRef.current);
            }
        };
    }, []);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setShowAnimate2(true);
                }
            },
            { threshold: 0.1 } 
        );

        if (content2Ref.current) {
            observer.observe(content2Ref.current);
        }

        return () => {
            if (content2Ref.current) {
                observer.unobserve(content2Ref.current);
            }
        };
    }, []);
    return(
        <div className="service-container">
                <h1 className="service-title title_lines">Services</h1>
                <div className="service-inner-container">
                    <div className="service-imag">
                        <img src={Ubanner} />
                    </div>
                    <div className={`service-content ${showAnimate ? 'show-animate' : ''}`} ref={contentRef}>
                        <h1 className="animate">Real-Time Utility Monitoring System</h1>
                        <h4 className="animate">Gain Control and Optimize Resources</h4>
                        <p className="animate">Empower your facility with a comprehensive real-time utility monitoring system. 
                            Achieve greater efficiency, cost savings, and sustainability across all your utility resources.</p>
                            <button  className="button-71 animate" onClick={()=>{navigate('/services/utility'); scrollToTopSmoothly();}}>Learn More</button>
                    </div>
                </div>
                <div className="service-inner-container2">
                    <div className={`service-content2  ${showAnimate2 ? 'show-animate2' : ''}`} ref={content2Ref}>
                        <h1 className="animate2">Real-Time Energy Monitoring</h1>
                        <h4 className="animate2">Unleash Efficiency and Savings</h4>
                        <p className="animate2">Take control of your facility's energy consumption with a cutting-edge real-time energy monitoring system. Gain actionable insights, optimize usage,
                             and slash your energy bills.</p>
                             <button  className="button-71 animate2"  onClick={()=>{navigate('/services/energy'); scrollToTopSmoothly();}}>Learn More</button>
                    </div>
                    <div className="service-imag2">
                        <img src={EnergyBanner3} />
                    </div>
                </div>

               
                <div class="blob"></div>
        </div>
    )
}
export default Service;