import React, { useState } from 'react';
import "./Style/Dropdown.css"
const FAQItem = ({ faq, isOpen, toggleAccordion }) => (
    <div className={`faq-item ${isOpen ? 'open' : ''}`}>
      <div className="faq-questions" onClick={toggleAccordion}>
        <div >{faq.question}</div>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      <p className="faq-answers">{faq.answer}</p>
    </div>
  );
  

const FAQList = ({ faqs ,ques}) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = index => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className='faq-inner'>
        <div className="faq-list">
        {faqs.map((faq, index) => (
            <FAQItem
            key={faq.id}
            faq={faq}
            isOpen={openIndex === faq.id}
            toggleAccordion={() => toggleAccordion(faq.id)}
            />
        ))}
        </div>
    </div>
  );
};

const DropDown = ({faqs}) => {
 
 
  return (
    <div className='aboutUs'>

      <FAQList faqs={faqs} />
    </div>
  );
};

export default DropDown;
