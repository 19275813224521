import "./Styles/utitlityben.css"
import { useInView } from 'react-intersection-observer';
function Benefits(){
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger only once when the component comes into view
        threshold: 0.1 // Adjust this as necessary
    });
    return(
        <div className={`utility-benefit-container  utility-benefit-fade-up ${inView ? 'visible' : ''}`} ref={ref}>
            <ul>
             <li style={{backgroundColor:"#0B374D"}} >
              
                <div class="title">Enhanced Visibility</div>
                <div class="descr">Gain a holistic view of your utility consumption across all resources</div>
            </li>
            <li style={{backgroundColor:"#1286A8"}}>
                
                <div class="title">Improved Efficiency</div>
                <div class="descr">Identify areas of excessive usage and implement targeted conservation measures</div>
            </li>
            <li  style={{backgroundColor:"#D2B53B"}}>
                
                <div class="title">Cost Savings</div>
                <div class="descr">Reduce utility bills by optimizing resource allocation and preventing leaks or inefficiencies</div>
            </li>
            <li  style={{backgroundColor:"#0b484d"}}>
              
                <div class="title">Predictive Maintenance</div>
                <div class="descr">Analyse sensor data to predict equipment failures and schedule proactive maintenance, minimizing downtime</div>
            </li>
            <li  style={{backgroundColor:"#11577a"}}>
                {/* <div class="icon"><i class="fa-brands fa-github"></i></div> */}
                <div class="title">Sustainability</div>
                <div class="descr">Promote environmentally conscious practices by tracking resource consumption and identifying opportunities for reduction</div>
            </li>
</ul>
        </div>
    )
}
export default Benefits