import React from "react";
import "./Styles/predictive.css"
// import img from "../../common/DelayedImg";
import Maintain from "../../../assets/SiteSenz_Images/Predictive Maintenance/Key Features/Predictive-Maintenance-Models.webp"
import Machine from "../../../assets/SiteSenz_Images/Predictive Maintenance/Key Features/Machine-Learning-Algorithms.webp"
import Anomly from "../../../assets/SiteSenz_Images/Predictive Maintenance/Key Features/Anamoly-Detection.webp"
import Root from "../../../assets/SiteSenz_Images/Predictive Maintenance/Key Features/Root-Cause-Analysis.webp"
import Risk from "../../../assets/SiteSenz_Images/Predictive Maintenance/Key Features/Risk-Assessment.webp"
import Schedule from "../../../assets/SiteSenz_Images/Predictive Maintenance/Key Features/Maintenance-Scheduling-Optimization.webp"
import { useInView } from 'react-intersection-observer';
function Blogs() {
  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger only once when the component comes into view
    threshold: 0.1 // Adjust this as necessary
});
  return (
    <div className="key-container">
		<h1 className="title_lines">Key Features of SiteSenz Predictive Analytics</h1>
		<div className={`key-inner-container predictive-key-fade-up ${inView ? 'visible' : ''} `} ref={ref}>
        <div class="cards">
            <div className="predictive-card-image">
                <img src={Maintain } alt="Avatar" width='100%' className="predictive-maintenance"/>
            </div>
          <div class="containers">
            <h4 className="predictive-key-title">Predictive Maintenance Models</h4> 
            <p>Develop customized predictive models for specific equipment types, enabling early fault detection and proactive maintenance</p> 
          </div>
        </div>
		<div class="cards">
            <div className="predictive-card-image">
                <img src={Machine } alt="Avatar" width='100%'  className="predictive-machine"/>
            </div>
          <div class="containers">
            <h4 className="predictive-key-title">Machine Learning Algorithms</h4> 
            <p>Our system utilizes sophisticated machine learning algorithms to analyse sensor data and historical trends, identifying patterns and predicting potential failures</p> 
          </div>
        </div>
		
		<div class="cards">
        <div className="predictive-card-image">
          <img src={Schedule } alt="Avatar" width='100%'  className="predictive-schedule"/>
          </div>
          <div class="containers">
            <h4 className="predictive-key-title">Maintenance Scheduling Optimization</h4> 
            <p>Optimize maintenance schedules based on predicted equipment health, minimizing downtime and maximizing equipment availability</p> 
          </div>
        </div>
		<div class="cards">
        <div className="predictive-card-image">
          <img src={Root } alt="Avatar" width='100%'  className="predictive-rootcause"/>
          </div>
          <div class="containers">
            <h4 className="predictive-key-title2">Root Cause Analysis</h4> 
            <p>Identify the root cause of potential problems, enabling targeted maintenance and addressing underlying issues.</p> 
          </div>
        </div>
		
		<div class="cards">
        <div className="predictive-card-image">
          <img src={Risk } alt="Avatar" width='100%'  className="predictive-risk"/>
          </div>
          <div class="containers">
            <h4  className="predictive-key-title2">Risk Assessment</h4> 
            <p>Evaluate maintenance risks, prioritize critical assets, and allocate resources based on asset criticality and failure probabilities</p> 
          </div>
        </div>
       
		<div class="cards">
        <div className="predictive-card-image">
          <img src={Anomly } alt="Avatar" width='100%'  className="predictive-anomaly"/>
          </div>
          <div class="containers">
            <h4  className="predictive-key-title2">Anomaly Detection</h4> 
            <p>Receive alerts for abnormal readings or deviations from expected machine behaviour, allowing for early intervention</p> 
          </div>
        </div>
		</div>
    </div>
  );
}

export default Blogs;
