import Header from "../../common/Header";
import Footer from "../../common/Footer";
import "./Styles/production.css"
import Banner from "./ProductionBanner"
import Machine from "../../../assets/Image/ServicesImages/Production Monitoring/Icons/Machine Connectivity.svg"
import Bottle from "../../../assets/Image/ServicesImages/Production Monitoring/Icons/Identifying & Resolving Bottlenecks.svg"
import StremLined from "../../../assets/Image/ServicesImages/Production Monitoring/Icons/Streamlined Workflow Optimization.svg"
import Benefits from "./ProductionBenefits";
import ProductionImg from "../../../assets/SiteSenz_Images/Production Monitoring/Banner/3811712.webp"
import { useInView } from 'react-intersection-observer';
function Production(){
    const { ref, inView } = useInView({
        triggerOnce: true, 
        threshold: 0.1
    });
    return(
        <div className="production-service">
            <Header/>
            <Banner />
            <div className="production-container">
               <div className="production-inner-container">               
                    <div className={`production-vertical productive-key-fade-up ${inView ? 'visible' : ''}`} ref={ref}>
                        <div className="production-data">
                            <img src={Machine} alt="" className="production-icon"/>
                            <div className="production-title">Machine Connectivity</div>
                            <p className="production-description">
                                Connects to any machine on your shop floor, allowing you to collect and visualize data effortlessly, With real-time insights.
                            </p>
                        </div>
		
                        <div className="production-data">
                            <img src={Bottle} alt="" className="production-icon"/>
                            <div className="production-title">Identifying & Resolving Bottlenecks</div>   
                                <p className="production-description">
                                    Harness the power of historical data to pinpoint production bottlenecks and constraints that lead to downtime and identify inefficiencies and take proactive measures to optimize your processes and maximize productivity
                                </p>                        
                        </div>
                
                        <div className="production-data">
                            <img src={StremLined} alt="" className="production-icon"/>
                            <div className="production-title">Streamlined Workflow Optimization</div>
                            <p className="production-description">Build customized workflows to ensure prompt action is taken when issues arise. Empowers you to notify the right person at the right time, 
                            facilitating swift resolution of production challenges and minimizing disruptions to your operations.</p> 
                        </div>
			        </div> 
                    
                    <div className='industry' >
                        <div className={`production-inner-industry productive-key-fade-up ${inView ? 'visible' : ''}`} ref={ref}>
                        <div className="industry-container">
                            <div className="industry-title">PROBLEM: LACK OF VISIBLITY</div>
                            <p className="industry-content">Unlocking accurate, real time monitoring data is hard. Most manufacturers manually collect data from their shop floor and enter it into management systems. This is time-sensitive and leads to delayed, inaccurate production data that is not actionable. </p>
                            <p className="industry-content" style={{marginTop:'12px'}}>This results in massive inefficiencies, from an inability to drive process improvements or justify capital expenditures, to unexpected machine downtime and production losses.</p>
                            <p className="solution">THE SOLUTION</p>
                            <p className="solution1">Continuous improvement in manufacturing starts with capturing data from your operations – specifically your machine assets. 
                                SiteSenz has automated machine data collection to deliver data driven insights in a matter of minutes.</p>
                            <p className="solution2">The data captured from equipment drives immediate insights for both people and system and enabling them to make better, faster decisions as well as drive automation.</p>
                        </div>
                        <div className="production-industry-img">
                           <img src={ProductionImg} className="production-img"/>
                        </div>
                        </div>
                    </div>
                    <div className="prod-eff-title">UNLOCK EFFICIENCY WITH POWERFUL ANALYTICS</div>
                    <div className="prod-eff-sub">Empowers you to make informed decisions with real-time data analytics, providing comprehensive insights, down to each individual parts to work orders. Ensures clear view of your shop floor.</div>
                    <div className="prod-benefits">
                        <Benefits />
                    </div>
               </div>
               
            </div>
            <Footer />
        </div>
    )
}
export default Production;