
import { useInView } from 'react-intersection-observer';
function Benefits(){
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger only once when the component comes into view
        threshold: 0.1 // Adjust this as necessary
    });
    return(
        <div className={`utility-benefit-container  predictive-key-fade-up ${inView ? 'visible' : ''}`} ref={ref}>
            <ul>
                <li style={{backgroundColor:"#0B374D"}} >
                
                    <div class="title">Reduced Downtimes</div>
                    <div class="descr">Anticipate and address potential equipment failures before they disrupt production.</div>
                </li>
                <li style={{backgroundColor:"#1286A8"}}>
                    
                    <div class="title">Lower Maintenance Costs</div>
                    <div class="descr">Eliminate unnecessary preventive maintenance and focus resources on critical issues</div>
                </li>
                <li  style={{backgroundColor:"#D2B53B"}}>
                    
                    <div class="title">Improved Reliability</div>
                    <div class="descr">Enhance equipment reliability, reduce breakdowns, and increase overall operational reliability for consistent performance</div>
                </li>
                <li  style={{backgroundColor:"rgb(11, 72, 77)"}}>
                
                    <div class="title">Data-Driven Decisions</div>
                    <div class="descr">Make informed maintenance decisions, prioritize tasks based on data insights, and optimize maintenance schedules for efficiency</div>
                </li>
                <li  style={{backgroundColor:"#0B374D"}}>
                    {/* <div class="icon"><i class="fa-brands fa-github"></i></div> */}
                    <div class="title">Safety and Compliance</div>
                    <div class="descr">Ensure equipment safety, comply with regulatory standards, and mitigate risks associated with equipment failures or malfunctions
                </div>
                </li>
            </ul>
        </div>
    )
}
export default Benefits