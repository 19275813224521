
import Blob1 from "../../../assets/Svg/blob1.svg"
import { useState,useEffect } from "react"
import Banner from "../../../assets//SiteSenz_Images/Production Monitoring/Banner/3301590.webp"
function ProductionBanner(){
    const [showAnimate, setShowAnimate] = useState(false);

    useEffect(() => {
        // Add the class after a short delay to trigger the animation
        const timer = setTimeout(() => {
            setShowAnimate(true);
        }, 100); // Adjust the delay as needed
        return () => clearTimeout(timer);
    }, []);
    return(
        <div class="banner-container">
            <div className="banner-inner-container">
                <div className={`banner-content-container ${showAnimate ? 'show-animate' : ''}`}>
                    <div className="heading animate">
                       PRODUCTION MONITORING
                    </div>
                    <div className="short-heading animate">
                    SiteSenz revolutionizes production monitoring with automated OEE tracking, machine utilization analysis, 
                    and downtime detection, providing you with unparalleled insights into your operations
                    </div>
                    
                </div>
                <div className="banner-image-contaier">
                    <img src={Blob1} className="blob-image"/>
                    <img 
                    src={Banner} className="production-banner-image"
                    />
                </div>
               
            </div>  
          
        </div>
    )
}
export default ProductionBanner