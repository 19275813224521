
import Header from "./Component/common/Header"
import Hero from "./Component/Home/Hero";
import AboutBanner from "./Component/Home/About";
import KeyFeature  from "./Component/Home/KeyFeatures";
import Service from "./Component/Home/Service"
import Service2 from "./Component/Home/Service2";
import WhyChoose from "./Component/Home/WhyChoose";
import FAQ from "./Component/Home/FAQ";
import Footer from "./Component/common/Footer";
import QA from "./Component/Home/QA"
import { useEffect } from "react";
import ReactGA from "react-ga"
import "./App.css"
function App() {
	useEffect(()=>{
    ReactGA.pageview(window.location.pathname)
	},[])
  return (
    <div className="App">
		<Header />
		<Hero /> 
		<AboutBanner />
		<KeyFeature />
		<Service />
		<Service2 />
		<WhyChoose />
		{/* <FAQ /> */}
		<QA />
		<Footer />
    </div>
  );
}

export default App;
