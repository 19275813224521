
import Header from "./Component/common/Header"
import Footer from "./Component/common/Footer"
import Form from "./Component/Contact/Form"
function Contact(){
    return(
        <div>
                <Header />
                <Form />
                <Footer />
        </div>
    )
}
export default Contact