import "./Styles/productionBenefits.css"
import { useInView } from 'react-intersection-observer';
function Benefits(){
    const { ref, inView } = useInView({
        triggerOnce: true, // Trigger only once when the component comes into view
        threshold: 0.1 // Adjust this as necessary
    });
    return( 
        <div className={`production-benefit-container productive-key-fade-up ${inView ? 'visible' : ''}`} ref={ref}>
            <ul>
                <li style={{backgroundColor:"#0B374D"}} >
                
                    <div class="title">Downtime and Quality Analysis</div>
                    <div class="descr">Manage downtime events and quality issues with detailed reporting and Pareto charts. By identifying the most common reasons for inefficiency</div>
                </li>
                <li style={{backgroundColor:"#1286A8"}}>
                    
                    <div class="title">Job & Operator Repoting</div>
                    <div class="descr">Monitor the performance of each production run by comparing it against historical data including shift, cell, machine, and operator</div>
                </li>
                <li  style={{backgroundColor:"#D2B53B"}}>
                    
                    <div class="title">Optimize OEE Performance</div>
                    <div class="descr">Visualize, analyse, and optimize actual cycle times to enhance Overall Equipment Effectiveness (OEE). 
                    Empowering you to continuously monitor and improve OEE across your production operations.</div>
                </li>
            </ul>
        </div>
    )
}
export default Benefits