import "../../Styles/Service.css"
import Utility from "../../assets/Image/Services/utility1.png"
import { useNavigate } from "react-router-dom";

import Predictive from "../../assets/SiteSenz_Images/Home Page/20943761.webp"
import Production from "../../assets/SiteSenz_Images/Home Page/3301590.webp"
import { useState,useEffect,useRef } from "react";
function Service2(){
    const navigate = useNavigate();
    const contentRef = useRef(null);
    const content2Ref = useRef(null)
    function scrollToTopSmoothly() {
        const scrollStep = -window.scrollY / (500 / 15); // Adjust the speed here (500 is the duration in milliseconds)
        const scrollInterval = setInterval(() => {
            if (window.scrollY !== 0) {
                window.scrollBy(0, scrollStep);
            } else {
                clearInterval(scrollInterval);
            }
        }, 15);
    }
    const [showAnimate, setShowAnimate] = useState(false);
    const [showAnimate2, setShowAnimate2] = useState(false);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setShowAnimate(true);
                }
            },
            { threshold: 0.1 } 
        );

        if (contentRef.current) {
            observer.observe(contentRef.current);
        }

        return () => {
            if (contentRef.current) {
                observer.unobserve(contentRef.current);
            }
        };
    }, []);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setShowAnimate2(true);
                }
            },
            { threshold: 0.1 } 
        );

        if (content2Ref.current) {
            observer.observe(content2Ref.current);
        }

        return () => {
            if (content2Ref.current) {
                observer.unobserve(content2Ref.current);
            }
        };
    }, []);
    return(
        <div className="service-container">
                
                {/* 3 and 4 */}
                <div className="service-inner-container3">
                    <div className="service-imag3">
                        <img src={Predictive}/>
                    </div>
                    <div  className={`service-content ${showAnimate ? 'show-animates' : ''}`} ref={contentRef}>
                        <h1 className="animates">Predictive Analysis & Preventive Maintenance</h1>
                        <h4 className="animates">Predict and Prevent Issues with SiteSenz Predictive Analytics</h4>
                        <p className="animates">Proactive Insights for Optimal Performance Move from reactive maintenance to proactive problem-solving with SiteSenz's advanced predictive analytics engine. Anticipate potential equipment failures, optimize maintenance schedules, and minimize downtime.</p>
                        <button className="button-71 animates" onClick={()=>{navigate('/services/Predictive&Preventive-maintenance'); scrollToTopSmoothly();}}>Learn More</button>
                    </div>
                </div>
                <div class="blob1"></div>
                <div className="service-inner-container4">
                    
                    <div className={`service-content2  ${showAnimate2 ? 'show-animates2' : ''}`} ref={content2Ref}>
                        <h1 className="animates2">Production Monitoring</h1>
                        <h4 className="animates2">Ensuring efficiency and quality every step of the way</h4>
                        <p className="animates2">By gathering real-time data, you can identify bottlenecks, prevent downtime, and optimize your processes.</p>
                        <button  className="button-71 animates2 " onClick={()=>{navigate('/services/Production-monitoring'); scrollToTopSmoothly();}}>Learn More</button>
                    </div>
                    <div className="service-imag2">
                        <img src={Production}/>
                    </div>
                </div>
                
        </div>
    )
}
export default Service2;