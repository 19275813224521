import React from "react";
import Alert1 from "../../../assets/SiteSenz_Images/Utility Monitoring/Key Features/Alerts & Notification.webp"
import Historical from "../../../assets/SiteSenz_Images/Utility Monitoring/Key Features/Historical Analysis.webp"
import Scalablity from "../../../assets/Image/KeyFeatures/Scalablity.svg"
import "./Styles/utility.css"
import Multi from "../../../assets/SiteSenz_Images/Utility Monitoring/Key Features/Multi-Utility Monitoring.webp"
import Customizable from "../../../assets/SiteSenz_Images/Utility Monitoring/Key Features/Customizable Dashboard.webp"
import Data from "../../../assets/SiteSenz_Images/Utility Monitoring/Key Features/Real Time Data Collection.webp"
import { useInView } from 'react-intersection-observer';
function Blogs() {
	const { ref, inView } = useInView({
        triggerOnce: true, // Trigger only once when the component comes into view
        threshold: 0.1 // Adjust this as necessary
    });

  return (
    <div className="key-container utility-key-container">
		<h1 className="utility-key-features-title title_lines">Key Features of Real-Time Utility Monitoring</h1>
		<div className={`key-inner-container  utility-key-fade-up ${inView ? 'visible' : ''}`} ref={ref}>
        <div className="cards">
         <div className="utility-card-img">
		  <img 
		  	src={Multi } alt="Avatar" width='100%' className="utility-monitoring"
		  />
		  </div>
          <div class="containers">
            <h4 className="utility-card-title">MULTI - UTILITY MONITORING</h4> 
            <p>Our system is designed to monitor multiple utilities simultaneously, providing a holistic view of your facility's resource consumption.</p> 
          </div>
        </div>
		<div className="cards">
		<div className="utility-card-img">
		  <img 
		   src={Data } alt="Avatar" width='100%'className="utility-realtime-data"
		  />
		  </div>
          <div class="containers">
            <h4 className="utility-card-title real-time-utility-title">REAL - TIME DATA COLLECTION</h4> 
            <p>With an advanced edge gateway, we collect and process data in real time, ensuring up-to-the-minute accuracy and actionable insights.</p> 
          </div>
        </div>
		<div className="cards">
		<div className="utility-card-img">
		  <img 
		  	src={Customizable } alt="Avatar" width='100%' className="utility-customized"
		  />
		  </div>
          <div class="containers">
            <h4 className="utility-card-title">CUSTOMIZABLE DASHBOARD</h4> 
            <p>Tailor-made dashboards allow you to visualize utility data in the format that suits your needs, from charts and graphs to detailed reports</p> 
          </div>
        </div>
		
		<div  className="cards">
		<div className="utility-card-img">
		  <img  
		  	src={Alert1 } alt="Avatar" width='100%' className="utility-alert"
		  />
		  </div>
          <div class="containers utility-history-containers">
            <h4 className="utility-alert-key-title">ALERT & NOTIFICATIONS</h4> 
            <p>Stay informed about anomalies or inefficiencies with automated alerts and notifications, empowering proactive maintenance and cost savings.</p> 
          </div>
        </div>
		
		<div  className="cards">
		<div className="utility-card-img">
		  <img 
		   src={Historical } alt="Avatar" width='100%' className="utility-history"
		  />
		  </div>
          <div class="containers utility-history-containers">
            <h4 className="utility-history-key-title">HISTORICAL ANALYSIS</h4> 
            <p>Dive deep into historical data to identify trends, patterns, and opportunities for optimization, driving continuous improvement initiatives</p> 
          </div>
    </div>
		</div>
    </div>
  );
}

export default Blogs;
