import About from "../../assets/Svg/About.svg";
import Expertise from "../../assets/Svg/Expertise.svg"
import Values from "../../assets/Svg/Ourvalue.svg";
import { useInView } from 'react-intersection-observer';
function AboutBanner(){
    const { ref, inView } = useInView({
        triggerOnce: true, 
        threshold: 0.1 
    });
    return(
        <>
        <section className={`about-container about-fade-up ${inView ? 'visible' : ''}`} ref={ref}>            
            <div className="card" >
                <div className="icon"><img src={About} /></div>
                <div className="content">
                    <h2>About Us</h2>
                    <p>Our team is clear in vision to propel the industry into the future with real-time insights, boosting efficiency, and productivity.</p>
                </div>
            </div>
            <div className="card">
                <div className="icon"> <div className="icon"><img src={Values} /></div>
                </div>
                <div className="content">
                    <h2>Our values</h2>
                    <p>Our engineers are dedicated to advancing digital manufacturing innovation, driven by passion. We uphold integrity, 
                        transparency, and a steadfast commitment to our clients' success
                    </p>
                </div>
            </div>
            <div className="card">
                <div className="icon"> <div className="icon"><img src={Expertise} /></div>
                </div>
                <div className="content">
                    <h2>Expertise</h2>
                    <p>
                    Our SiteSenz engineers aren't just professionals; they're seasoned industry veterans and tech innovators. With deep expertise in manufacturing, they collaborate to deliver impactful solutions.
                    </p>
                </div>
            </div>
            {/* </section> */}
            
        {/* </motion.section> */}
       {/*  */}
        </section>
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200">
         
          <rect x="0" y="0" width="1440" height="200" fill="#333F44" />
            <path fill="#333F44" fill-opacity="1" d="M0,160L40,138.7C80,117,160,75,240,85.3C320,96,400,160,480,170.7C560,181,640,139,720,112C800,85,880,75,960,69.3C1040,64,1120,64,1200,80C1280,96,1360,128,1400,144L1440,160L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path></svg> */}
       </>
    )
}
export default AboutBanner