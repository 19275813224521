import React from "react";
import Logo from "../../assets/Image/Logo.png"
import FB from "../../assets/Svg/fb-icon.svg"
import Insta from "../../assets/Svg/insta-icon.svg"
import Twitter from "../../assets/Svg/twitter-icon.svg"
import "../../Styles/Footer.css"
import { useNavigate } from "react-router-dom";
function Footer() {
	const navigate = useNavigate();
  return  (
  <div className="custom-footer">
		<div className="container mb-12 text-center sm:text-left grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
		<div className="footer-inner-container">
				<div className="mx-auto sm:ml-0 logo-container" >
					<img
						className="cursor-pointer"
						src={Logo}
						alt="logo"
					/>

					<div className="gap-4 flex "  >
					<img className="icon-button" src={Insta} alt=""   onClick={() => window.open('https://www.instagram.com/accounts/login/?hl=en',  '_blank')} style={{cursor:"pointer"}}/>
					<img className="icon-button" src={FB} alt=""  onClick={() => window.open('https://www.facebook.com/login/','_blank')} style={{cursor:"pointer"}}/>
					<img onClick={() => window.open('https://x.com/i/flow/login', '_blank')}
						className="icon-button"
						src={Twitter}
						alt=""
						style={{cursor:"pointer"}}
					/>
					</div>
				</div>

				<div className="address1-container">
						<h6 className="font-medium text-xl mb-4" >USA (Sales)</h6>
						<p>MN Engineering Solutions Inc.  <br/>332 S Michigan Avenue,
						<br/> 121 5695, Chicago, IL 60604, USA</p>
						<p>M (USA): +1 (269) 625-5034</p>
				</div>

				<div className="address2-container" >
					<h6 className="font-medium text-xl mb-4">USA HQ</h6>
					<p>MN Engineering Solutions Inc.  <br/>2412 W Heather Road,<br/>Suite 210, Wilmington DE 19803, USA</p>
					<p>M (USA): +1 (269) 625-5034</p>
				</div>

			</div>
		</div>
		<p className="text-center mt-12">
			Copyright © 2024 SiteSenz. All rights reserved.
		</p>
	</div>
);

}

export default Footer;

export function Link({text}) {
  return <p className="cursor-pointer mt-2">{text}</p>;
}
