import "../../Styles/WhyChoose.css"
import DataSafe from "../../assets/Image/WhyChoose/Datasafe.svg"
import Expertise from "../../assets/Svg/Expertise.svg"
import Flexible from "../../assets/Image/WhyChoose/Flexible.svg"
import Long from "../../assets/Image/WhyChoose/Long.svg"
import subscription from "../../assets/Image/WhyChoose/Subscription.svg"
function WhyChoose(){
    return(
        <div className="why-choose-container">
            <h1 className=" title_lines">Why Choose Us</h1>
            <div className="timeline">

                <div className="time-container left-container">
                    <img src={DataSafe} className="time-img"/>
                    <div className="text-box">
                    <img src={DataSafe} className="mobile-time-img" />
                        <h2>Your Data is Safe</h2>
                        <smal></smal>
                       
                        <p>Our multi-layered security framework ensures thorough data protection,
                             covering user authentication, access controls, data transmission, and storage</p>
                             <span className="left-container-arrow"></span>
                    </div>
                </div>
                <div className="time-container right-container ">
                <img src={Flexible} className="time-img" />
                    <div className="text-box2 right-box">
                    <img src={Flexible} className="mobile-time-img"/>
                        <h2>More Flexible Solutions</h2>
                        <smal></smal>
                        <p>Customized to match your unique needs</p>
                        <span className="right-container-arrow"></span>
                    </div>
                </div>
                <div className="time-container left-container">
                <img src={subscription} className="time-img"/>
                    <div className="text-box">
                    <img src={subscription} className="mobile-time-img"/>
                        <h2>Subcription Plans Customized for You</h2>
                        <smal></smal>
                        <p>Discover tailored subscription plans for your facility, including cost-effective cloud options</p>
                        <span className="left-container-arrow"></span>
                    </div>
                </div>
                <div className="time-container right-container">
                <img src={Expertise} className="time-img"/>
                    <div className="text-box right-box">
                    <img src={Expertise} className="mobile-time-img"/>
                        <h2>Our Expertise</h2>
                        <smal></smal>
                        <p>Utilize our skilled management team to optimize your operations affordably</p>
                        <span className="right-container-arrow"></span>
                    </div>
                </div>
                <div className="time-container left-container">
                <img src={Long} className="time-img"/>
                    <div className="text-box">
                    <img src={Long} className="mobile-time-img"/>
                        <h2>Long - Term Support</h2>
                        <smal></smal>
                        <p>We offer ongoing support and can develop models ar any requires level, be it process, line, system, asset, or production</p>
                        <span className="left-container-arrow"></span>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default WhyChoose;