// import "./Styles/utility.css"
import Blob1 from "../../../assets/Svg/blob1.svg"
import Banner from "../../../assets/SiteSenz_Images/Energy Monitoring/Banner/Wavy_REst-03_Single-08.webp"
import { useState,useEffect } from "react"
function Energybanner(){
    const [showAnimate, setShowAnimate] = useState(false);

    useEffect(() => {
        // Add the class after a short delay to trigger the animation
        const timer = setTimeout(() => {
            setShowAnimate(true);
        }, 100); // Adjust the delay as needed
        return () => clearTimeout(timer);
    }, []);
    return(
        <div class="banner-container">
            <div className="banner-inner-container">
                <div className={`banner-content-container ${showAnimate ? 'show-animate' : ''}`}>
                    <div className="heading animate">
                    Real-Time Energy Monitoring Matters
                    </div>
                    <div className="short-heading animate">
                     Efficient energy management is not just about cost savings; it's about sustainability, resilience, and regulatory compliance<br /><br />
                    Traditional energy monitoring often relies on monthly meter readings, leaving you blind to usage patterns in between. 
                    Real-time monitoring bridges this gap, providing a constant stream of data on your facility's energy consumption. 
                    By monitoring energy usage in real time, businesses can
                    </div>
                    
                </div>
                <div className="banner-image-contaier">
                    {/* <img src={Blob1} className="blob-image"/> */}
                    <img
                    src={Banner} className="energy-banner-image"
                    />
                    {/* <img  src={Banner} className="energy-banner-image" /> */}
                </div>
               
            </div>  
          
        </div>
    )
}
export default Energybanner