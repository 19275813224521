import "../../Styles/hero.css"
import Blob1 from "../../assets/Svg/blob1.svg"
import Banner1 from "../../assets/Image/banner1.png"
import Banner from "../../assets/SiteSenz_Images/Home Page/Dashboard.webp"
import { useState,useEffect } from "react"

function Hero(){
    const [showAnimate, setShowAnimate] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAnimate(true);
        }, 100); 
        return () => clearTimeout(timer);
    }, []);
    return(
        <div class="banner-container ">
            <div className="banner-inner-container ">
                <section  className={`banner-content-container ${showAnimate ? 'show-animate' : ''}`}>
                    <div className="heading animate">
                        Unlocking Manufacturing Potential with Real-Time Insights
                    </div>
                    <div className="short-heading animate">
                        Welcome to SiteSenz, your portal to digitization and manufacturing excellence.
                        Our real-time monitoring optimizes production efficiency and  quality, driving Industry 4.0 integration. SiteSenz is your transformation catalyst
                    </div>
                </section>
                <div className="banner-image-contaier">
                    <img src={Blob1} className="blob-image"/>
                    <img  src={Banner} className="banner-image" />
                </div>
            </div>  
          
        </div>
    )
}
export default Hero